import React, { useContext, useEffect } from "react"
import queryString from 'query-string'

import Layout from "../components/layout"
import SEO from "../components/seo"
import cartContext from "../contexts/cart-context"

import "./checkout-success.css"
import { Link } from "gatsby"

const CheckoutSuccess = ({ location }) => {
  const { removeAll } = useContext(cartContext)
  const params = queryString.parse(location.search)
  const id = params.session_id

  useEffect(() => {
    removeAll() //cleaning up the cart after payment succeed
  }, [removeAll])

  return (
    <Layout footer={true}>
      <SEO title="MERCI !" lang="fr" />
      <section id="checkout-success">
        <h1>
          Merci pour votre commande !
        </h1>
        <p>
          Vous allez recevoir un email de confirmation pour votre commande.
        </p>
        <p>
          Votre numéro de commande est le n°<strong>{id}</strong>.<br />
          Merci de conserver ce numéro qui vous sera utile pour toute question ou réclamation.
        </p>
        <p>
          Pour toute question ou réclamation, veuillez <Link to={"/#contact"}>nous contacter</Link> !
        </p>
      </section>
    </Layout>
  )
}

export default CheckoutSuccess
